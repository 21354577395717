import React, { useRef } from 'react';
import Layout from '@components/Layout';
import SEO from '@components/Seo';
import styles from './styles.module.scss';
import Hero from '@views/case-studies/entrepreneurs-forge/Hero';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Description from '@views/case-studies/Description';
import Contents from '@views/case-studies/the-gundies/Contents';
import { Divider } from '@components/Grid';
import DevelopingABrand from '@views/case-studies/entrepreneurs-forge/DevelopingABrand';
import { graphql } from 'gatsby';
import LogoDesign from '@views/case-studies/entrepreneurs-forge/LogoDesign';
import Execution from '@views/case-studies/entrepreneurs-forge/Execution';
import RecentCaseStudies from '@components/RecentCaseStudies';
import StyleScapes from '@views/case-studies/entrepreneurs-forge/StyleScape';


const EntrepreneursForge = ({ data }) => {

    const $introduction = useRef(null);
    const $brandDev = useRef(null);
    const $logoDesign = useRef(null);
    const $execution = useRef(null);
    const $stylescapes = useRef(null);

    return (
        <Layout>
            <SEO
                title='Entrepreneurs Forge • Case Studies'
                image={data?.efPreview?.publicURL}
                description='A new sub-brand for the University of Suffolk to express growth, innovation and technology.'
            />

            {/*Start of hero introduction*/}

            <div className={styles.caseStudyWrapper}>
                <Hero
                    background={data?.hero?.childImageSharp?.fluid}
                    title='Entrepreneurs Forge.'
                    text='A new brand to express growth, innovation and technology.'
                />
                <ProjectOverview
                    client='Entrepreneurs Forge'
                    name='Branding Guidelines, Graphic Design'
                    backgroundColor='#FFBF0B'
                    description={
                        <p>
                            A new sub-brand for the University of Suffolk to express growth, innovation and technology.
                        </p>
                    }
                />

                <Contents
                    svg={data?.contents?.publicURL}
                    svgAlt='person'
                    list={[
                        { title: '1. Introduction', ref: $introduction },
                        {
                            title: '2. Developing a Brand',
                            ref: $brandDev,
                        },
                        {
                            title: '3. Logo Design',
                            ref: $logoDesign,
                        },
                        {
                            title: '4. StyleScape',
                            ref: $stylescapes,
                        },
                        {
                            title: '4. Execution',
                            items: [
                                'Social Media',
                                'Emails',
                                'Printed Assets',
                            ],
                            ref: $execution,
                        },
                    ]}
                />

                <Description
                    textLeft={
                        'The Entrepreneurs Forge is an immersive development programme designed to create and foster innovation that is strong, enduring, and successful.'
                    }
                    textRight={
                        <div>
                            <p>
                                Leading and driving the strengthening of Suffolk’s digitally creative SMEs, and the
                                employability of Suffolk’s students and graduates, the programme will forge strong bonds
                                between participants to support personal and business recovery and resilience.
                            </p>
                        </div>
                    }
                />

            </div>

            {/*End of Hero introduction*/}

            <Divider xsMargin={5} smMargin={5} />

            {/*<DevelopingABrand*/}
            {/*    ref={$brandDev} />*/}

            <DevelopingABrand
                ref={$brandDev}
                svgQuestion={data?.question?.publicURL}
                svgQuestionAlt='question mark'
                imageCollage={[
                    data?.research01?.childImageSharp?.fluid,
                    data?.research02?.childImageSharp?.fluid,
                ]}
            />

            <Divider xsMargin={5} smMargin={5} />
            <Divider margin={5} />
            <Divider margin={5} />

            <LogoDesign
                ref={$logoDesign}
                logoCollectionImage={[
                    data?.logoCollection01?.childImageSharp?.fluid,
                    data?.logoCollection02?.childImageSharp?.fluid,
                ]}
                logoGeometrySVG={[
                    data?.logoGeometry01?.publicURL,
                    data?.logoGeometry02?.publicURL,
                ]}
            />

            <Divider xsMargin={5} smMargin={5} />
            <Divider margin={5} />
            <Divider margin={5} />

            <StyleScapes
                ref={$stylescapes}
                sliderImages={[
                    data?.styleScape01?.childImageSharp?.fluid,
                    data?.styleScape02?.childImageSharp?.fluid,
                    data?.styleScape03?.childImageSharp?.fluid,
                    data?.styleScape04?.childImageSharp?.fluid,
                ]}
            />

            <Divider margin={5} />
            <Divider margin={5} />
            <Divider margin={5} />

            <Execution
                ref={$execution}
                imageGrid={data?.socialGrid?.childImageSharp?.fluid}
                imageEmailTemplates={data?.email?.childImageSharp?.fluid}
                sliderImages={[
                    data?.print01?.childImageSharp?.fluid,
                    data?.print02?.childImageSharp?.fluid,
                    data?.print03?.childImageSharp?.fluid,
                ]}
            />

            <RecentCaseStudies />

        </Layout>
    );
};

// noinspection GraphQLUnresolvedReference
export const query = graphql`
    query {
        efPreview: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-hero.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-hero.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        contents: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-contents.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        question: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-question.svg/" }) {
            publicURL
        }
        research01: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-research01.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        research02: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-research02.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        logoCollection01: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-logo-collage01.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        logoCollection02: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-logo-collage02.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        logoGeometry01: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-logo.svg/" }) {
            publicURL
        }
        logoGeometry02: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-logo-type.svg/" }) {
            publicURL
        }
        styleScape01: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-stylescape-01.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        styleScape02: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-stylescape-02.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        styleScape03: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-stylescape-03.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        styleScape04: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-stylescape-04.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        socialGrid: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-repeat-social-grid.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        email: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-emails.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        print01: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-print-01.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        print02: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-print-02.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        print03: file(absolutePath: { regex: "/case-studies-entrepreneurs-forge-print-03.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

export default EntrepreneursForge;

