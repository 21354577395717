import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Img from 'gatsby-image';
import SVG from 'react-inlinesvg';
import Button from '@components/Button';
import Link from 'gatsby-link';

const LogoDesign = ({ logoCollectionImage, logoGeometrySVG }, ref) => {
    return (
        <section ref={ref}>
            <Grid>
                <Row>
                    <Col xs={12} lg={6}>
                        <h2>Six Logo ideas generated</h2>

                        <p>We conducted a design study of six primary concepts, with ten designs internally judged. </p>

                        <p>These logos carried a theme of company values, name, letterforms and relevant symbolismed
                            mixed together to create a memorable mark that described ‘Entrepreneurs Forge’ to it’s
                            target market.</p>
                    </Col>

                    <Col xs={12} lg={6}>
                        <Img fluid={logoCollectionImage[0]} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={6}>
                        <Img fluid={logoCollectionImage[1]} imgStyle={{ objectFit: 'cover' }} />
                    </Col>

                    <Col xs={12} lg={6}>
                        <Divider margin={3} />
                        <h2>Two final Logos pitched</h2>
                        <p>The refinement process was strict, only allowing a certain number of concepts through the
                            process.</p>

                        <p>Internal and selective external research studies influenced the final result. We ensured that
                            those questioned had a non-bias understanding of what the logo represented and whether it
                            spoke to them.</p>
                    </Col>
                </Row>

                <Divider xsMargin={5} smMargin={5} />
                <Divider margin={5} />

                <Row className={styles.logoSvgWrapper} middle={'xs'}>
                    <Col xs={12} lg={4}>
                        <SVG alt={'logo geometry'} src={logoGeometrySVG[0]} />
                    </Col>

                    <Col xs={12} lg={8}>
                        <SVG alt={'logo geometry'} src={logoGeometrySVG[1]} />
                    </Col>
                </Row>

                <Divider xsMargin={5} smMargin={5} />

                <Row>
                    <Col xs={12} lg={6}>
                        <h2>Geometry and typography at harmony with one another.</h2>
                        <p>Perfect geometrical and visually balanced alignment help keep the onlooker looking.</p>
                        <p>Want a logo with perfect aesthetical precision?</p>
                        <Link to={'/service/graphic-design'}>
                            <Button text={'Read more'} />
                        </Link>
                    </Col>

                    <Col xs={12} lg={6}>
                        <p>A logo that represents choice, channels, connections. Inspired by digital circuitry the mark
                            appears as an “E, F“ for Entrepreneurs Forge.</p>

                        <p> The colour represents variety in the users journey and convey opportunity with rich
                            colour.</p>
                    </Col>
                </Row>

            </Grid>

        </section>
    );
};

export default forwardRef(LogoDesign);
