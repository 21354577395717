import React, { forwardRef } from 'react';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import Button from '@components/Button';
import ScrollTextWithImage from '@components/ScrollTextWithImage';
import { Divider, Grid } from '@components/Grid';
import CustomSwiper from '@components/CustomSwiper';
import { SwiperSlide } from 'swiper/react';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';

const Execution = ({ imageGrid, imageEmailTemplates, sliderImages }, ref) => {
    return (
        <section ref={ref}>
            <div className={styles.executionTitle}>
                <h2>We started by creating several research boards.</h2>
            </div>

            <Divider margin={5} />

            <div>
                <Img fluid={imageGrid} imgStyle={{ objectFit: 'cover' }} />
            </div>

            <Divider margin={5} />
            <Divider margin={5} />

            <Grid>
                <ScrollTextWithImage imageFluid={imageEmailTemplates}>
                    <div className={styles.quickAndConvertingContent}>
                        <h2>An Engaging Email Campaign.</h2>
                        <p>
                            Even though Element Softworks did not execute the brand strategy for Entrepreneurs Forge, we
                            provided many marketing templates to ensure a consistent brand image was viewed by their
                            audience.
                        </p>
                        <p>It was crucial that the hard-work of developing the brand was executed in all assets.</p>
                    </div>
                </ScrollTextWithImage>
            </Grid>

            <Divider margin={5} />
            <Divider margin={5} />

            <CustomSwiper>
                {sliderImages?.map((image) => (
                    <SwiperSlide key={image?.src}>
                        <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                    </SwiperSlide>
                ))}
            </CustomSwiper>

            <Divider margin={5} />
            <Divider margin={5} />

            <div className={styles.executionTitle}>
                <h2>The project was finalised with a comprehensive branding guideline book and training given on key
                    software to the University team.</h2>
            </div>

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more about our <br /> Branding services?
                    </h2>
                }
                buttonText='Read more'
                link='/service/graphic-design/branding-strategy'
            />

        </section>
    );
};

export default forwardRef(Execution);
;
