import React, { forwardRef } from 'react';
import { Col, Grid, Row } from '@components/Grid';
import CustomSwiper from '@components/CustomSwiper';
import { SwiperSlide } from 'swiper/react';
import Img from 'gatsby-image';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';

const StyleScapes = ({ sliderImages }, ref) => {
    return (
        <section ref={ref}>
            <Grid>
                <Row>
                    <Col xs={12} lg={6}>
                        <h2>Four Stylescapes were pitched</h2>
                    </Col>

                    <Col xs={12} lg={6}>
                        <p>A Stylescape describes the specific values of a brand condensed into a single stylistic sheet
                            that can be judged at once.</p>
                    </Col>
                </Row>
            </Grid>
            <CustomSwiper>
                {sliderImages?.map((image) => (
                    <SwiperSlide key={image?.src}>
                        <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                    </SwiperSlide>
                ))}
            </CustomSwiper>

            <KnowMoreCta
                text={
                    <h2>
                        Want your own <br /> Stylescapes designed?
                    </h2>
                }
                buttonText='Read more'
                link='/service/graphic-design/branding-strategy'
            />

        </section>
    );
};

export default forwardRef(StyleScapes);
