import React, { forwardRef } from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import styles from './styles.module.scss';
import SVG from 'react-inlinesvg';
import Img from 'gatsby-image';

const DevelopingABrand = ({ imageCollage, svgQuestion, svgQuestionAlt }, ref) => {
    return (
        <section ref={ref}>
            <Grid>
                <Row>
                    <Col xs={12} lg={4}>
                        <div className={styles.developingABrandContentSvg}>
                            <SVG alt={svgQuestionAlt} src={svgQuestion} />
                        </div>
                    </Col>

                    <Col xs={12} lg={7}>
                        <div className={styles.developingABrandContentText}>
                            <h2>How to develop a brand?</h2>
                            <p>A brand is not a logo…</p>

                            <p>The beginnings of a brand starts at researching the target market. This is often
                                associate with the core business development of the company; however, the designer may
                                contribute by
                                developing market understanding. Designers help with developing the mission statement
                                and core
                                values which transcend into visual research that represents the brand.</p>

                            <p>We work hard to mix and match, and combine this research into several unique styles that
                                give the audience the feeling of the core brand values. This prepares your audience with
                                an
                                expectation of the type of product that you deliver, whether its premium, technical,
                                luxury, for-the-masses, innovative… the list goes on.</p>

                            <p>This compromises the core development of a brand. The rest is just execution of style
                                and marketing.</p>

                        </div>
                    </Col>
                </Row>

                <Divider xsMargin={5} smMargin={5} />

                <div className={styles.developingABrandResearchTitle}>
                    <h2>We started by creating several research boards.</h2>
                </div>

                <Divider xsMargin={5} smMargin={5} />

                <Row>
                    <Col xs={12} lg={6}>
                        <Img fluid={imageCollage[0]} imgStyle={{ objectFit: 'cover' }} />
                    </Col>

                    <Col xs={0} lg={6}>
                        <Img fluid={imageCollage[1]} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default forwardRef(DevelopingABrand);
