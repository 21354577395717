import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Tooltip from 'rc-tooltip';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import TooltipGlossary from '@components/TooltipGlossary';

const Description = ({ image, textLeft, textRight }) => {
    return (
        <section className={styles.descriptionWrapper}>
            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>{textLeft}</h2>
                    </Col>
                    <Col xs={12} md={6}>
                        {textRight}
                    </Col>
                </Row>

                <Divider margin={5} />
                <Divider smMargin={0} mdMargin={5} />

                <Row>
                    <Col xs={12}>
                        <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default Description;
